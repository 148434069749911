import { formatMoney } from '@scripts/utils/helpers'

const ProductForm = ({ productId, productPrice, productOldPrice }) => {
  return {
    gadgetInstance: null,

    tieredDiscounts: [],
    tieredDiscountsIds: [],
    tieredDiscountsQtys: [],
    selectedTier: null,

    quantity: 1,

    productPriceMoney: Number(productPrice) / 100,
    productOldPriceMoney: Number(productOldPrice) / 100,
    productCollectionsJSON: [],

    calculatedPrice: '',
    calculatedOldPrice: '',

    init () {
      const scriptTagContent = this.$refs.productCollections.innerText
      this.productCollectionsJSON = JSON.parse(scriptTagContent)

      const env = Shopify.shop.includes('dev') ? '--development' : ''

      const script = document.createElement('script')

      script.src = `https://aussie-magnets-tiered-discount-v2${env}.gadget.app/api/client/web.min.js`
      script.defer = true

      document.head.appendChild(script)

      script.onload = async () => {
        this.gadgetInstance = new Gadget()

        await this.initTieredDiscounts()

        this.updateCalculatedPrice()

        this.$watch('quantity', (value) => {
          if (value === '') {
            this.quantity = 1
          }

          if (this.tieredDiscounts.length) {
            this.updateCalculatedPrice()
          }

          this.updateSelectedTier()
        })
      }

      script.onerror = () => {
        console.error('Failed to load the Gadget script.')
      }
    },

    updateCalculatedPrice () {
      let applicableTier = this.tieredDiscounts[0]
      for (const tier of this.tieredDiscounts) {
        if (this.quantity <= tier.quantity) {
          applicableTier = tier
          break
        }
      }

      if (applicableTier) {
        let totalPrice
        if (applicableTier.type === 'fixed') {
          const basePrice = this.quantity * this.productPriceMoney
          totalPrice = basePrice - (applicableTier.discountAmount * this.quantity)
        } else {
          const pricePerUnit = parseFloat(applicableTier.price.replace(window.theme.currencySymbol, ''))
          totalPrice = this.quantity * pricePerUnit
        }
        this.calculatedPrice = formatMoney(totalPrice)
        this.calculatedOldPrice = formatMoney(this.quantity * this.productPriceMoney)
      } else {
        this.calculatedPrice = formatMoney(this.quantity * this.productPriceMoney)
      }
    },

    updateSelectedTier () {
      for (const tier of this.tieredDiscounts) {
        if (this.quantity <= tier.quantity) {
          this.selectedTier = tier.id
          break
        }
      }
    },

    async initTieredDiscounts () {
      try {
        const response = await this.gadgetInstance.tieredDiscounts.findMany({
          first: 250,
          select: {
            mode: true,
            collections: true,
            products: true,
            tiers: {
              edges: {
                node: {
                  id: true,
                  type: true,
                  subtitle: true,
                  quantity: true,
                  discountAmount: true,
                  title: true
                }
              }
            }
          }
        })

        const filteredResponse = response.filter((tierDiscount) => {
          if (tierDiscount.mode !== 'ACTIVE') {
            return false
          }
          if (tierDiscount.collections.length !== 0) {
            const collectionIDs = tierDiscount.collections.map((collection) =>
              Number(collection.id.split('/').pop())
            )
            let matched = false
            for (const item of this.productCollectionsJSON) {
              if (collectionIDs.includes(item.id)) {
                matched = true
                break
              }
            }
            return matched
          }
          if (tierDiscount.products.length !== 0) {
            const productIds = tierDiscount.products.map((product) =>
              product.id.split('/').pop()
            )
            return productIds.includes(productId)
          }

          return false
        })

        const productDiscount = filteredResponse[0]

        if (!productDiscount) return

        this.tieredDiscounts = productDiscount.tiers.edges.map((tier) => {
          let price
          if (tier.node.type === 'default') {
            price = this.productPriceMoney
          } else if (tier.node.type === 'percent') {
            price = this.productPriceMoney * (1 - (tier.node.discountAmount / 100))
          } else if (tier.node.type === 'fixed') {
            price = this.productPriceMoney - tier.node.discountAmount
          }
          price = price.toFixed(2) // Ensure the price has two decimal places
          return { ...tier.node, price: `${window.theme.currencySymbol}${price}` }
        })

        const initialTier = this.tieredDiscounts.find((tier) => tier.quantity >= 1)

        this.tieredDiscountsIds = this.tieredDiscounts.map(discount => discount.id)
        this.tieredDiscountsQtys = this.tieredDiscounts.map(discount => discount.quantity)

        this.selectedTier = initialTier ? initialTier.id : null

        this.updateCalculatedPrice()
      } catch (error) {
        console.error('Error in fetching discounts from Gadget', error)
      }
    },

    onQtyInput () {
      this.quantity = Number(this.quantity.replace(/[^0-9]/g, ''))

      this.qtyLimiter()
    },

    onQtyBtnClick (operation) {
      if (operation === 'add') {
        this.quantity = Number(this.quantity) + 1

        this.qtyLimiter()

        return
      }

      this.quantity = Number(this.quantity) - 1 === 0 ? 1 : Number(this.quantity) - 1
    },

    qtyLimiter () {
      if (this.quantity >= 999999) {
        this.quantity = 999999
      }
    },

    onTierOptionChange () {
      const selectedTierObject = this.tieredDiscounts.find(discount => discount.id === this.selectedTier)

      if (!selectedTierObject) return

      if (selectedTierObject.type === 'default') {
        this.quantity = 1
      } else {
        const selectedTierIndex = this.tieredDiscountsIds.indexOf(this.selectedTier)

        if (selectedTierIndex > 0) {
          this.quantity = this.tieredDiscounts[selectedTierIndex - 1].quantity + 1
        }
      }
    }
  }
}

export default ProductForm
